import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-8">
      <div className="container mx-auto max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-300 mb-4">
            At VoiceInk, we take your privacy seriously. This Privacy Policy explains how we handle any information when you use our macOS application.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Information Collection</h2>
          <p className="text-gray-300 mb-4">
            VoiceInk processes voice data either locally on your device or through cloud services, depending on your chosen settings. When using local processing:
          </p>
          <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
            <li>All data is processed entirely on your device</li>
            <li>No voice data leaves your computer</li>
            <li>No tracking of usage or behavior</li>
            <li>No collection of personal information</li>
          </ul>
          <p className="text-gray-300 mt-4">
            When using cloud processing:
          </p>
          <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4 mb-4">
            <li>Voice data is processed by third-party cloud services you choose</li>
            <li>Data processing is subject to the respective third-party's privacy policies</li>
            <li>You should review the privacy policies of the cloud service providers for details on their data handling practices</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Data Processing</h2>
          <p className="text-gray-300 mb-4">
            VoiceInk offers two processing modes: local and cloud-based. Local processing happens entirely on your device using local AI models. Cloud processing utilizes third-party services and their AI models, where data processing occurs on their servers according to their terms of service and privacy policies.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Data Storage</h2>
          <p className="text-gray-300 mb-4">
            Any temporary voice data processed by VoiceInk is immediately discarded after conversion to text. We do not maintain any database of user recordings or transcriptions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Third-Party Services</h2>
          <p className="text-gray-300 mb-4">
            When using cloud processing features, VoiceInk integrates with third-party AI services. These services have their own privacy policies and terms of service that govern how they process and handle your data. We recommend reviewing their respective policies for complete understanding of their data practices.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Updates to Privacy Policy</h2>
          <p className="text-gray-300 mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last updated" date.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
          <p className="text-gray-300 mb-4">
            If you have any questions about this Privacy Policy, please contact us at{' '}
            <a href="mailto:prakashjoshipax@gmail.com" className="text-blue-400 hover:text-blue-300">
              prakashjoshipax@gmail.com
            </a>
          </p>
        </section>

        <footer className="text-gray-400 text-sm">
          Last updated: March 27, 2024
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
