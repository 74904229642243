import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

const Buy: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Helmet>
        <title>Buy VoiceInk Pro</title>
        <script src="https://cdn.jsdelivr.net/npm/@polar-sh/checkout@0.1/dist/embed.global.js" defer data-auto-init></script>
      </Helmet>

      <header className="px-4 md:px-6 h-16 md:h-20 flex items-center w-full bg-gray-900/80 backdrop-blur-sm shadow-lg">
        <div className="container mx-auto flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2 md:space-x-3">
            <img src="/appIcon.png" alt="VoiceInk Logo" width={32} height={32} className="rounded-lg md:w-10 md:h-10" />
            <span className="text-xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
              VoiceInk
            </span>
          </Link>
        </div>
      </header>

      <main className="flex-1 pt-16 md:pt-20">
        <section className="w-full py-16 md:py-24 lg:py-32 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse"></div>
          <div className="container mx-auto px-6 relative z-10">
            <div className="max-w-4xl mx-auto text-center mb-12">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-500/10 to-purple-500/10 
                  border border-blue-500/20 rounded-full px-4 py-1 mb-8"
              >
                <Check className="w-4 h-4 text-blue-400" />
                <span className="text-blue-400 font-medium">Lifetime Deal -  Early Access Pricing</span>
              </motion.div>
              
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 }}
                className="text-5xl md:text-6xl font-bold"
              >
                <span className="text-white">Upgrade to </span>
                <span className="relative inline-block text-transparent bg-clip-text 
                  bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400
                  animate-gradient-x">
                  VoiceInk Pro
                </span>
              </motion.h1>
              
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="text-gray-400 text-xl mt-6"
              >
                One-time payment, lifetime updates, unlimited features
              </motion.p>
            </div>

            <div className="max-w-4xl mx-auto bg-gray-800/30 backdrop-blur-xl rounded-2xl border border-gray-700/50 overflow-hidden shadow-[0_8px_32px_rgba(0,0,0,0.3)]">
              <iframe 
                src="https://buy.polar.sh/polar_cl_4DlCAyxq-oStoKBi_JXJdgx595-PCV_etJ-gPUZB4ac"
                className="w-full h-[800px] border-none"
                title="VoiceInk Checkout"
              />
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-gray-900 to-transparent"></div>
        </section>
      </main>

      <footer className="py-6 md:py-8 w-full px-4 md:px-6 border-t border-gray-700">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <p className="text-xs md:text-sm text-gray-400">© 2024 VoiceInk. All rights reserved.</p>
          <nav className="mt-4 sm:mt-0 flex space-x-4 md:space-x-6">
            <Link to="/privacy" className="text-xs md:text-sm text-gray-400 hover:text-blue-400 transition-colors">
              Privacy Policy
            </Link>
            <Link to="/terms" className="text-xs md:text-sm text-gray-400 hover:text-blue-400 transition-colors">
              Terms of Service
            </Link>
            <a href="mailto:prakashjoshipax@gmail.com?subject=VoiceInk" className="text-xs md:text-sm text-gray-400 hover:text-blue-400 transition-colors">
              Contact Us
            </a>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Buy; 