import React from 'react';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { ArrowRight, Sparkles, Mic, Zap, Cpu } from 'lucide-react';

const Hero = () => {
  return (
    <section className="relative min-h-[90vh] pt-32">
      {/* Main hero content */}
      <div className="relative max-w-[1400px] mx-auto px-4 lg:px-8 mb-16">
        <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
          {/* Left column - Main content */}
          <div className="relative z-10">
            {/* "New" badge */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-500/10 to-purple-500/10 
                border border-blue-500/20 rounded-full px-4 py-1 mb-8"
            >
              <Sparkles className="w-4 h-4 text-blue-400" />
              <span className="text-blue-400 font-medium">Now with Cloud Processing</span>
            </motion.div>

            {/* Main headline */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative"
            >
              <h1 className="text-5xl sm:text-6xl lg:text-7xl xl:text-8xl font-bold leading-[1.1] mb-8">
                <span className="block text-white">Leave The</span>
                <span className="block mt-2 relative">
                  <span className="relative inline-block text-transparent bg-clip-text 
                    bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400
                    animate-gradient-x">
                    Keyboard
                  </span>
                </span>
                <span className="block text-white">Club Behind</span>
              </h1>
            </motion.div>

            {/* Subheadline */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="max-w-xl relative"
            >
              <div className="absolute -left-4 top-0 w-1 h-full bg-gradient-to-b from-blue-500/50 via-purple-500/50 to-transparent rounded-full"></div>
              <p className="text-xl sm:text-2xl lg:text-3xl leading-[1.4] mb-12 pl-6 space-y-4">
                <span className="block text-white font-bold bg-gradient-to-r from-white via-white to-gray-300 bg-clip-text">
                  While they're typing, you're finishing.
                </span>
                <span className="block text-gray-400 font-medium">
                  While they're drafting, you're sending.
                </span>
                <span className="block text-white font-bold bg-gradient-to-r from-white via-white to-gray-300 bg-clip-text">
                  While they're editing, you're already on to the next big thing.
                </span>
              </p>
              <div className="absolute -right-4 bottom-0 w-1 h-1/2 bg-gradient-to-t from-purple-500/50 via-pink-500/50 to-transparent rounded-full"></div>
            </motion.div>

            {/* CTA section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="flex flex-col sm:flex-row items-start gap-6"
            >
              <ScrollLink to="pricing" smooth={true} duration={500} className="w-full sm:w-auto">
                <button className="group relative w-full sm:w-auto px-8 h-16 bg-white
                  rounded-2xl font-bold text-lg text-black overflow-hidden transform active:scale-[0.98]
                  transition-all duration-200 flex items-center justify-center gap-3 hover:bg-gray-100"
                >
                  <span className="relative z-10">Download for Mac</span>
                  <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </ScrollLink>

              <ScrollLink to="how-it-works" smooth={true} duration={500} className="w-full sm:w-auto">
                <button className="group w-full sm:w-auto h-16 px-8 rounded-2xl font-bold text-lg transition-all duration-200
                  bg-black hover:bg-gray-900 border border-white/10
                  text-white flex items-center justify-center gap-3"
                >
                  <ArrowRight className="w-5 h-5" />
                  Watch Demo
                </button>
              </ScrollLink>
            </motion.div>
          </div>

          {/* Right column - Visual elements */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.4 }}
            className="relative lg:h-[700px] hidden lg:block"
          >
            {/* Main app window */}
            <div className="relative z-20 bg-gradient-to-br from-gray-900 to-black border border-white/10 
              rounded-3xl shadow-2xl overflow-hidden backdrop-blur-xl"
            >
              {/* Window header */}
              <div className="px-6 py-4 border-b border-white/10 flex items-center gap-2">
                <div className="flex gap-2">
                  <div className="w-3 h-3 rounded-full bg-red-500/80" />
                  <div className="w-3 h-3 rounded-full bg-yellow-500/80" />
                  <div className="w-3 h-3 rounded-full bg-green-500/80" />
                </div>
              </div>
              
              {/* App content */}
              <div className="p-8">
                <img 
                  src="/appIcon.png" 
                  alt="VoiceInk Interface" 
                  className="w-full h-auto rounded-xl shadow-lg"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Value proposition section */}
      <div className="relative w-full border-t border-white/5">
        <div className="max-w-[1400px] mx-auto px-4 lg:px-8 py-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-12"
          >
            {/* AI Introduction */}
            <div className="flex items-center justify-center gap-3">
              <div className="w-12 h-12 rounded-xl bg-black border border-white/10 flex items-center justify-center shadow-lg">
                <img src="/favicon-32x32.png" alt="VoiceInk AI" className="w-8 h-8" />
              </div>
              <span className="text-xl font-semibold bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent">
                Supercharged by AI
              </span>
            </div>

            {/* Benefits Grid */}
            <div className="grid md:grid-cols-2 gap-6 lg:gap-8 max-w-5xl mx-auto">
              <div className="group relative flex items-start gap-4 p-4 lg:p-6">
                <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 border border-blue-500/20 flex items-center justify-center">
                  <Mic className="w-5 h-5 text-blue-400" />
                </div>
                <div className="flex-1 space-y-2">
                  <p className="text-lg text-white font-medium">Speak naturally, get perfect text</p>
                  <p className="text-gray-400">No more "umms" and "uhhs" - our AI cleans up your speech automatically</p>
                </div>
              </div>

              <div className="group relative flex items-start gap-4 p-4 lg:p-6">
                <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-purple-500/10 to-pink-500/10 border border-purple-500/20 flex items-center justify-center">
                  <Zap className="w-5 h-5 text-purple-400" />
                </div>
                <div className="flex-1 space-y-2">
                  <p className="text-lg text-white font-medium">Smart formatting that just works</p>
                  <p className="text-gray-400">Proper paragraphs, punctuation, and formatting without lifting a finger</p>
                </div>
              </div>

              <div className="group relative flex items-start gap-4 p-4 lg:p-6">
                <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-pink-500/10 to-blue-500/10 border border-pink-500/20 flex items-center justify-center">
                  <ArrowRight className="w-5 h-5 text-pink-400" />
                </div>
                <div className="flex-1 space-y-2">
                  <p className="text-lg text-white font-medium">Works everywhere you do</p>
                  <p className="text-gray-400">Instantly paste your transcribed text in any app - emails, messages, documents</p>
                </div>
              </div>

              <div className="group relative flex items-start gap-4 p-4 lg:p-6">
                <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 border border-blue-500/20 flex items-center justify-center">
                  <Cpu className="w-5 h-5 text-blue-400" />
                </div>
                <div className="flex-1 space-y-2">
                  <p className="text-lg text-white font-medium">Your choice of power</p>
                  <p className="text-gray-400">Use fast local AI or connect to cloud models like GROQ for even better results</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 