'use client'

import React, { useEffect } from 'react'
import { ArrowRight, Mic, Zap, Lock, Keyboard, Globe, Cpu, Download, Check, Laptop, Menu, X } from "lucide-react"
import { motion } from 'framer-motion'
import { Link as ScrollLink, Element } from 'react-scroll'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import Hero from './components/Hero';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

const DownloadButton: React.FC<ButtonProps & { 
  size?: 'default' | 'small',
  variant?: 'default' | 'pro'
}> = ({ 
  children, 
  className, 
  size = 'default',
  variant = 'default',
  ...props 
}) => {
  const sizeClasses = {
    default: "h-[56px] text-[17px] px-8",
    small: "h-[44px] text-[15px] px-6"
  }

  const iconSize = size === 'default' ? "w-[22px] h-[22px]" : "w-[18px] h-[18px]"

  const variantClasses = {
    default: `
      bg-white
      text-black
      transition-all
      duration-200
      hover:opacity-90
      active:scale-[0.98]
    `,
    pro: `
      bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100
      text-black
      transition-all
      duration-200
      hover:opacity-90
      active:scale-[0.98]
      border border-gray-300/20
    `
  }

  return (
    <button
      className={`
        relative
        rounded-xl
        font-semibold
        flex
        items-center
        justify-center
        gap-3
        w-full md:w-auto
        ${sizeClasses[size]}
        ${variantClasses[variant]}
        ${className}
      `}
      {...props}
    >
      <svg 
        className={iconSize}
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
      </svg>
      <span className="tracking-[-0.01em]">
        {children}
      </span>
    </button>
  )
};

export default function VoiceInkLandingPage() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  
  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  }

  const features = [
    { 
      icon: Mic, 
      title: "Voice-to-Text", 
      description: "Transform your speech into text with high accuracy using advanced AI models.",
      gradient: "from-blue-500/20 to-blue-600/20"
    },
    { 
      icon: Zap, 
      title: "Lightning Fast", 
      description: "Experience real-time transcription with local AI processing for instant results.",
      gradient: "from-yellow-500/20 to-yellow-600/20"
    },
    { 
      icon: Lock, 
      title: "Privacy Focused", 
      description: "Keep your data secure with local processing. Your voice never leaves your device.",
      gradient: "from-green-500/20 to-green-600/20"
    },
    { 
      icon: Globe, 
      title: "Multi-language", 
      description: "Support for multiple languages, allowing you to transcribe in your preferred tongue.",
      gradient: "from-purple-500/20 to-purple-600/20"
    },
    { 
      icon: Cpu, 
      title: "Cloud Models",
      isNew: true,
      description: "Access powerful cloud AI models like GROQ for enhanced accuracy and performance.",
      gradient: "from-rose-500/20 to-pink-600/20"
    },
    { 
      icon: Keyboard, 
      title: "Custom Hotkeys", 
      description: "Activate VoiceInk instantly with personalized keyboard shortcuts.",
      gradient: "from-pink-500/20 to-pink-600/20"
    },
    { 
      icon: Cpu, 
      title: "Offline Capable", 
      description: "Work without internet connection, perfect for sensitive environments.",
      gradient: "from-indigo-500/20 to-indigo-600/20"
    },
    { 
      icon: Laptop, 
      title: "Native Performance", 
      description: "Built as a native macOS app for blazing fast performance and seamless system integration.",
      gradient: "from-teal-500/20 to-teal-600/20"
    },
  ]

  const howItWorksSteps = [
    {
      title: "Download and Set Up",
      description: "Download VoiceInk and choose between local AI models or cloud providers. Set your preferred model in the app settings.",
      icon: Download
    },
    {
      title: "Configure Model",
      description: "For local models, download them directly. For cloud models like GROQ, add your API key in the settings for enhanced performance.",
      icon: Cpu
    },
    {
      title: "Configure Shortcut",
      description: "Go to settings and assign a custom shortcut key to activate the mini recorder view. This allows quick access from anywhere on your Mac.",
      icon: Keyboard
    },
    {
      title: "Activate and Record",
      description: "With VoiceInk running, use your shortcut to trigger the recorder from any application. Start speaking to record your voice.",
      icon: Mic
    },
    {
      title: "Stop Recording",
      description: "When you're done, stop the recording. VoiceInk will process your speech using your chosen AI model (local or cloud).",
      icon: Zap
    },
    {
      title: "Instant Transcription",
      description: "Your recording is quickly transcribed to text. The transcription is automatically copied to your clipboard.",
      icon: Cpu
    },
    {
      title: "Seamless Integration",
      description: "The transcribed text is instantly pasted at your current cursor position, allowing for a frictionless workflow in any application.",
      icon: ArrowRight
    }
  ];

  useEffect(() => {
    const cards = document.querySelectorAll('.group');
    
    const handleMouseMove = (e: MouseEvent) => {
      const card = (e.currentTarget as HTMLElement);
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      card.style.setProperty('--mouse-x', `${x}px`);
      card.style.setProperty('--mouse-y', `${y}px`);
    };

    cards.forEach(card => {
      card.addEventListener('mousemove', handleMouseMove as EventListener);
    });

    return () => {
      cards.forEach(card => {
        card.removeEventListener('mousemove', handleMouseMove as EventListener);
      });
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>VoiceInk - Voice to Text App for Mac | Speech Recognition Software</title>
        <meta name="title" content="VoiceInk - Voice to Text App for Mac | Speech Recognition Software" />
        <meta name="description" content="Transform your speech into text instantly with VoiceInk. Advanced AI-powered voice recognition for Mac with offline capability, multiple language support, and privacy focus." />
        
        {/* Senja Widget Script */}
        <script src="https://widget.senja.io/widget/e37797cf-8d74-48c9-9946-79c8d675a1a6/platform.js" type="text/javascript" async></script>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://voiceink.app/" />
        <meta property="og:title" content="VoiceInk - Transform Speech to Text Instantly on Mac" />
        <meta property="og:description" content="Local AI-powered voice recognition app for Mac. Convert speech to text instantly with offline capability, 100+ languages, and complete privacy." />
        <meta property="og:image" content="/og-image.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content="VoiceInk" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@beingpax" />
        <meta property="twitter:creator" content="@beingpax" />
        <meta property="twitter:title" content="VoiceInk - AI Voice Recognition for Mac" />
        <meta property="twitter:description" content="Transform your voice into text instantly with offline AI. Built for Mac, optimized for privacy." />
        <meta name="twitter:image" content="/og-image.png" />
        <meta name="twitter:image:alt" content="VoiceInk App Icon" />

        {/* Additional SEO tags */}
        <meta name="keywords" content="voice to text, speech recognition, mac app, offline voice recognition, AI transcription, voice typing, dictation software, macOS voice recognition, Apple Silicon optimized, local speech recognition, privacy-focused voice typing, multilingual voice recognition" />
        <meta name="author" content="VoiceInk" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://voiceink.app/" />

        {/* Language and locale */}
        <meta property="og:locale" content="en_US" />
        <html lang="en" />

        {/* Additional SEO meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta name="theme-color" content="#000000" />
        <meta name="application-name" content="VoiceInk" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="VoiceInk" />
      </Helmet>

      <div className="flex flex-col min-h-screen bg-black text-gray-100 relative">
        {/* Unified Global Background */}
        <div className="fixed inset-0 overflow-hidden pointer-events-none">
          {/* Grid pattern */}
          <div className="absolute inset-0" 
            style={{
              backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px)',
              backgroundSize: '50px 50px'
            }} 
          />

          {/* Main gradient orbs */}
          <div className="absolute top-[-10%] right-[-10%] w-[80%] h-[80%] rounded-full 
            bg-gradient-to-br from-blue-600 via-purple-600 to-pink-600 opacity-20 blur-[120px]" />
          
          <div className="absolute top-[30%] left-[-10%] w-[80%] h-[80%] rounded-full 
            bg-gradient-to-tr from-blue-600 via-indigo-600 to-purple-600 opacity-20 blur-[120px]" />
          
          <div className="absolute bottom-[-20%] right-[20%] w-[80%] h-[80%] rounded-full 
            bg-gradient-to-r from-purple-600 via-pink-600 to-blue-600 opacity-20 blur-[120px]" />
        </div>

        <header className={`px-4 md:px-6 h-16 md:h-20 flex items-center w-full fixed top-0 left-0 right-0 z-50 
          bg-black/40 backdrop-blur-md border-b border-white/[0.08]
          supports-[backdrop-filter]:bg-black/20`}>
          <div className="container mx-auto flex items-center justify-between">
            <div className="flex items-center space-x-2 md:space-x-3">
              <div className="relative group">
                <div className="absolute -inset-[1px] rounded-lg bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-sm group-hover:blur-md transition-all duration-300"></div>
                <img src="/appIcon.png" alt="VoiceInk Logo" width={32} height={32} className="relative rounded-lg md:w-10 md:h-10" />
              </div>
              <span className="text-xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                VoiceInk
              </span>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex space-x-6 lg:space-x-8">
              {['Features', 'How It Works', 'Pricing'].map((item) => (
                <ScrollLink 
                  key={item} 
                  to={item.toLowerCase().replace(/\s+/g, '-')} 
                  smooth={true} 
                  duration={500} 
                  className="text-sm font-medium text-gray-300 hover:text-white transition-colors cursor-pointer relative group"
                >
                  {item}
                  <span className="absolute left-0 bottom-0 w-full h-0.5 bg-gradient-to-r from-blue-400 to-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                </ScrollLink>
              ))}
            </nav>

            {/* Desktop Buttons */}
            <div className="hidden md:flex items-center gap-4">
              <Link to="/buy">
                <button className="h-[44px] px-6 text-[15px] font-semibold text-gray-300 hover:text-white transition-colors">
                  Buy Now
                </button>
              </Link>
              <ScrollLink to="pricing" smooth={true} duration={500}>
                <button className="h-[44px] px-6 text-[15px] font-semibold
                  bg-white text-black
                  rounded-xl
                  transition-all duration-200
                  hover:opacity-90
                  active:scale-[0.98]
                  flex items-center gap-2
                  shadow-[0_0_0_1px_rgba(255,255,255,0.1)]
                ">
                  <svg className="w-[18px] h-[18px]" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
                  </svg>
                  Download Now
                </button>
              </ScrollLink>
            </div>

            {/* Mobile Menu Button */}
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 text-gray-300 hover:text-white"
            >
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </header>

        {/* Mobile Menu */}
        <div className={`
          fixed inset-0 z-40 bg-black/95 backdrop-blur-lg transform transition-transform duration-300 ease-in-out
          ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
          md:hidden
        `}>
          <div className="flex flex-col h-full pt-20 px-6">
            <nav className="flex flex-col space-y-6">
              {['Features', 'How It Works', 'Pricing'].map((item) => (
                <ScrollLink 
                  key={item} 
                  to={item.toLowerCase().replace(/\s+/g, '-')} 
                  smooth={true} 
                  duration={500} 
                  onClick={() => setIsMenuOpen(false)}
                  className="text-lg font-medium text-gray-300 hover:text-white transition-colors"
                >
                  {item}
                </ScrollLink>
              ))}
            </nav>
            <div className="mt-8 flex flex-col gap-4 w-full">
              <Link to="/buy" onClick={() => setIsMenuOpen(false)} className="w-full">
                <button className="w-full h-[44px] text-[15px] font-semibold text-gray-300 hover:text-white transition-colors">
                  Buy Now
                </button>
              </Link>
              <ScrollLink 
                to="pricing" 
                smooth={true} 
                duration={500} 
                onClick={() => setIsMenuOpen(false)}
                className="w-full"
              >
                <button className="w-full h-[44px] text-[15px] font-semibold
                  bg-white text-black
                  rounded-xl
                  transition-all duration-200
                  hover:opacity-90
                  active:scale-[0.98]
                  flex items-center justify-center gap-2
                  shadow-[0_0_0_1px_rgba(255,255,255,0.1)]
                ">
                  <svg className="w-[18px] h-[18px]" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
                  </svg>
                  Download Now
                </button>
              </ScrollLink>
            </div>
          </div>
        </div>

        <main className="flex-1 relative">
          <Hero />

          {/* Testimonials Section */}
          <section className="w-full py-32 relative">
            <div className="container mx-auto px-6 relative">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-center max-w-2xl mx-auto mb-24"
              >
                <h2 className="text-4xl sm:text-5xl font-bold text-center mb-16">
                  <span className="text-white">Hear from our </span>
                  <span className="relative inline-block text-transparent bg-clip-text 
                    bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400
                    animate-gradient-x">
                    Users
                  </span>
                </h2>
              </motion.div>

              <div className="relative max-w-7xl mx-auto">
                {/* Senja Widget */}
                <div className="senja-embed" data-id="e37797cf-8d74-48c9-9946-79c8d675a1a6" data-mode="shadow" data-lazyload="false" style={{ display: 'block' }}></div>
              </div>
            </div>
          </section>

          <Element name="features">
            <section className="w-full py-16 md:py-24 relative">
              <div className="container mx-auto px-4 md:px-6">
                <motion.h2
                  variants={fadeInUpVariants}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="text-4xl sm:text-5xl font-bold text-center mb-16"
                >
                  <span className="text-white">Powerful </span>
                  <span className="relative inline-block text-transparent bg-clip-text 
                    bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400
                    animate-gradient-x">
                    Features
                  </span>
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {features.map((feature, index) => (
                    <motion.div
                      key={index}
                      variants={fadeInUpVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="group relative"
                    >
                      <div className="relative p-6 rounded-2xl bg-white/5 backdrop-blur-sm border border-white/10 
                        hover:border-white/20 transition-all duration-300 group h-[140px]">
                        <div className="absolute inset-0 rounded-2xl bg-gradient-to-br opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-out -z-10"
                             style={{background: `radial-gradient(600px circle at var(--mouse-x, 0) var(--mouse-y, 0), ${feature.gradient.split(' ')[1]}, transparent 40%)`}}>
                        </div>
                        <div className="flex gap-4 h-full">
                          <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-xl bg-gradient-to-br from-white/5 to-white/10 shadow-lg group-hover:scale-110 transition-transform duration-300">
                            <feature.icon className="w-5 h-5 text-gray-100" />
                          </div>
                          <div className="flex-1 space-y-2 min-w-0">
                            <div className="flex items-center gap-2">
                              <h3 className="text-lg font-semibold text-white truncate">{feature.title}</h3>
                              {feature.isNew && (
                                <span className="flex-shrink-0 bg-gradient-to-r from-blue-500 to-purple-500 text-white text-xs font-bold px-2 py-0.5 rounded-full">
                                  New
                                </span>
                              )}
                            </div>
                            <p className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-300 line-clamp-2">
                              {feature.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </section>
          </Element>

          <Element name="how-it-works">
            <section className="w-full py-16 md:py-24 relative">
              {/* Section divider - top */}
              <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-white/10 to-transparent" />
              
              <div className="container mx-auto px-4 md:px-6">
                <motion.h2
                  variants={fadeInUpVariants}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="text-4xl sm:text-5xl font-bold text-center mb-16"
                >
                  <span className="text-white">How </span>
                  <span className="relative inline-block text-transparent bg-clip-text 
                    bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400
                    animate-gradient-x">
                    VoiceInk
                  </span>
                  <span className="text-white"> Works</span>
                </motion.h2>

                <motion.div
                  variants={fadeInUpVariants}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="mb-20"
                >
                  <div className="relative w-full max-w-5xl mx-auto">
                    <div className="relative aspect-[16/10] rounded-2xl overflow-hidden shadow-2xl">
                      <iframe
                        className="absolute inset-0 w-full h-full"
                        src="https://www.youtube.com/embed/t9RFOHLCuow?start=0&end=616&rel=0"
                        title="VoiceInk Demo"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </motion.div>

                {/* New Grid Layout with minimalist style */}
                <div className="grid md:grid-cols-2 gap-6 lg:gap-8 max-w-5xl mx-auto">
                  {howItWorksSteps.map((step, index) => (
                    <motion.div
                      key={index}
                      variants={fadeInUpVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="group relative flex items-start gap-4 p-4 lg:p-6"
                    >
                      <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 
                        border border-blue-500/20 flex items-center justify-center">
                        <step.icon className="w-5 h-5 text-blue-400" />
                      </div>
                      <div className="flex-1 space-y-2">
                        <p className="text-lg text-white font-medium">{step.title}</p>
                        <p className="text-gray-400">{step.description}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>

              {/* Section divider - bottom */}
              <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-white/10 to-transparent" />
            </section>
          </Element>

          <Element name="pricing">
            <section className="w-full py-20 relative">
              <div className="container mx-auto px-6">
                <motion.h2
                  variants={fadeInUpVariants}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="text-4xl sm:text-5xl font-bold text-center mb-16"
                >
                  <span className="text-white">Buy Once </span>
                  <span className="relative inline-block text-transparent bg-clip-text 
                    bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400
                    animate-gradient-x">
                    Own Forever
                  </span>
                </motion.h2>
                
                <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                  {/* Free Trial Plan */}
                  <motion.div
                    variants={fadeInUpVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className="relative bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 
                      hover:border-white/20 transition-all duration-300"
                  >
                    <div className="absolute -top-4 left-4">
                      <span className="px-3 py-1 bg-blue-500/10 text-blue-400 text-sm font-medium rounded-full border border-blue-500/20">
                        Free Trial
                      </span>
                    </div>
                    
                    <div className="mt-4">
                      <h3 className="text-2xl font-bold text-white mb-4">Free</h3>
                      <div className="flex items-baseline mb-6">
                        <span className="text-5xl font-extrabold text-white">$0</span>
                      </div>
                      
                      <ul className="space-y-4 mb-8">
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-blue-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Full access to all local model features</span>
                        </li>
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-blue-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Unlimited recordings</span>
                        </li>
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-blue-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Community support</span>
                        </li>
                      </ul>
                      <p className="text-sm text-gray-400">
                        After 14 days, you'll be prompted to upgrade to Pro for an enhanced experience. All features remain accessible.
                      </p>
                    </div>
                  </motion.div>

                  {/* Pro Plan */}
                  <motion.div
                    variants={fadeInUpVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className="relative bg-white/[0.07] backdrop-blur-sm rounded-2xl p-8 border border-white/20 
                      hover:border-white/30 transition-all duration-300"
                  >
                    <div className="absolute -top-4 left-4">
                      <span className="px-3 py-1 bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-blue-400 
                        text-sm font-medium rounded-full border border-blue-500/20">
                        Early Access Price
                      </span>
                    </div>
                    
                    <div className="mt-4">
                      <h3 className="text-2xl font-bold text-white mb-4">Pro</h3>
                      <div className="flex items-baseline mb-6">
                        <span className="text-5xl font-extrabold bg-gradient-to-r from-yellow-400 to-orange-400 bg-clip-text text-transparent">$15</span>
                        <span className="ml-2 text-yellow-400">Lifetime</span>
                      </div>
                      
                      <ul className="space-y-4 mb-8">
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-yellow-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Everything in Free plan</span>
                        </li>
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-yellow-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">No upgrade prompts</span>
                        </li>
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-yellow-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Lifetime updates</span>
                        </li>
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-yellow-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Priority email support</span>
                        </li>
                        <li className="flex items-start">
                          <Check className="w-5 h-5 text-yellow-400 mt-0.5 mr-3 shrink-0" />
                          <span className="text-gray-300">Early access to new features</span>
                        </li>
                      </ul>
                    </div>
                  </motion.div>
                </div>
                
                <div className="mt-12 flex flex-col md:flex-row justify-center items-center gap-4 px-4 md:px-0">
                  <a 
                    href="https://github.com/Beingpax/VoiceInk/releases/download/v0.32/VoiceInk.dmg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full md:w-auto"
                  >
                    <DownloadButton>
                      Download Free
                    </DownloadButton>
                  </a>
                  
                  <Link to="/buy" className="w-full md:w-auto">
                    <DownloadButton variant="pro">
                      Buy Pro - $15
                    </DownloadButton>
                  </Link>
                </div>
              </div>
            </section>
          </Element>
        </main>

        {/* Footer */}
        <footer className="py-8 w-full px-4 md:px-6 border-t border-white/5 relative">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="flex items-center space-x-2">
                <img src="/appIcon.png" alt="VoiceInk Logo" width={24} height={24} className="rounded-lg" />
                <span className="text-sm text-gray-400">© 2024 VoiceInk. All rights reserved.</span>
              </div>
              <div className="flex items-center space-x-6">
                <Link to="/privacy" className="text-sm text-gray-400 hover:text-white transition-colors">
                  Privacy Policy
                </Link>
                <Link to="/terms" className="text-sm text-gray-400 hover:text-white transition-colors">
                  Terms of Service
                </Link>
                <a 
                  href="mailto:prakashjoshipax@gmail.com" 
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
