import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VoiceInkLandingPage from './VoiceInkLandingPage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Buy from './components/Buy';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<VoiceInkLandingPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/buy" element={<Buy />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;




