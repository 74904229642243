import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-8">
      <div className="container mx-auto max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
          <p className="text-gray-300 mb-4">
            By downloading, installing, or using VoiceInk ("the Software"), you agree to be bound by these Terms of Service. If you do not agree to these terms, do not use the Software.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. License and Services</h2>
          <p className="text-gray-300 mb-4">
            VoiceInk grants you a personal, non-transferable, non-exclusive license to use the Software on your Apple-branded computers in accordance with these Terms. The Software may utilize both local processing and cloud-based services for voice-to-text conversion.
          </p>
          <p className="text-gray-300 mb-4">
            When using cloud processing features, you acknowledge that:
          </p>
          <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4 mb-4">
            <li>Your voice data will be processed by third-party cloud services</li>
            <li>You are subject to the terms of service and privacy policies of these third-party providers</li>
            <li>You should review the respective policies of these services for their data handling practices</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Restrictions</h2>
          <p className="text-gray-300 mb-4">
            You may not:
          </p>
          <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
            <li>Copy, modify, or create derivative works of the Software</li>
            <li>Reverse engineer, decompile, or disassemble the Software</li>
            <li>Rent, lease, lend, sell, or sublicense the Software</li>
            <li>Remove any proprietary notices from the Software</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Updates</h2>
          <p className="text-gray-300 mb-4">
            The Software may automatically download and install updates. These updates are designed to improve, enhance, and further develop the Software and may include bug fixes, enhanced functions, new software modules, and completely new versions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Termination</h2>
          <p className="text-gray-300 mb-4">
            This license is effective until terminated. Your rights under this license will terminate automatically without notice if you fail to comply with any of its terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Refund Policy</h2>
          <p className="text-gray-300 mb-4">
            We want you to be completely satisfied with your purchase of VoiceInk. If you are not satisfied with your purchase, you may request a refund within 14 days of your purchase date.
          </p>
          <p className="text-gray-300 mb-4">
            To be eligible for a refund:
          </p>
          <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4 mb-4">
            <li>The refund request must be made within 14 days of purchase</li>
            <li>You must provide a valid reason for the refund request</li>
            <li>The software must be uninstalled from all devices</li>
          </ul>
          <p className="text-gray-300 mb-4">
            To request a refund, please{' '}
            <a 
              href="mailto:prakashjoshipax@gmail.com?subject=VoiceInk%20Refund%20Request" 
              className="text-blue-400 hover:text-blue-300"
            >
              contact us
            </a>
            {' '}with your order details and reason for the refund.
          </p>
          <p className="text-gray-300 mb-4">
            Once your refund request is approved, we will process the refund within 5-7 business days. The refund will be issued to the original payment method used for the purchase.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Disclaimer of Warranty</h2>
          <p className="text-gray-300 mb-4">
            The Software is provided "AS IS" without warranty of any kind. VoiceInk disclaims all warranties, whether express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Contact</h2>
          <p className="text-gray-300 mb-4">
            If you have any questions about these Terms, please{' '}
            <a 
              href="mailto:prakashjoshipax@gmail.com?subject=VoiceInk%20Terms%20Question" 
              className="text-blue-400 hover:text-blue-300"
            >
              contact us
            </a>.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">9. Third-Party Services</h2>
          <p className="text-gray-300 mb-4">
            The Software may integrate with third-party cloud services for voice processing. These services are governed by their own terms of service and privacy policies. Your use of such services is subject to their respective terms and conditions. We are not responsible for the practices of these third-party services.
          </p>
        </section>

        <footer className="text-gray-400 text-sm mt-8">
          Last updated: March 27, 2024
        </footer>
      </div>
    </div>
  );
};

export default TermsOfService; 